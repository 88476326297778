import { Component, Input, ChangeDetectionStrategy, AfterViewInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AppService } from '@shared/services/app.service';
import { LayoutService } from '@shared/services/layout.service';
import { UserService } from '@core/user/user.service';

@Component({
  selector: 'app-layout-sidenav',
  templateUrl: './layout-sidenav.component.html',
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSidenavComponent implements AfterViewInit {
  @Input() orientation = 'vertical';

  @HostBinding('class.layout-sidenav') private hostClassVertical = false;
  @HostBinding('class.layout-sidenav-horizontal') private hostClassHorizontal = false;
  @HostBinding('class.flex-grow-0') private hostClassFlex = false;

  userPermission$: Observable<number[]>;
  menuList = {
    '100': {
      icon: 'ion ion-ios-stats',
      route: '/dashboard',
      active: ['/dashboard', true],
      name: 'Dashboard.PageTitle'
    },
    '101': {
      icon: 'ion ion-ios-clipboard',
      route: '/case/inquiry',
      active: ['/case', false],
      name: 'Case.PageTitle'
    }
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private appService: AppService,
    private layoutService: LayoutService
  ) {
    // Set host classes
    this.hostClassVertical = this.orientation !== 'horizontal';
    this.hostClassHorizontal = !this.hostClassVertical;
    this.hostClassFlex = this.hostClassHorizontal;
    this.userPermission$ = this.userService.getUserPermission().shareReplay();
  }

  ngAfterViewInit() {
    // Safari bugfix
    this.layoutService._redrawLayoutSidenav();
  }

  getClasses() {
    let bg = this.appService.layoutSidenavBg;

    if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '');
    }

    return `${this.orientation === 'horizontal' ? 'container-p-x ' : ''} bg-${bg}`;
  }

  isActive(url: string, exact: boolean = true) {
    return this.router.isActive(url, exact);
  }

  isMenuActive(url: string) {
    return this.router.isActive(url, false);
  }

  isMenuOpen(url: string) {
    return this.router.isActive(url, false) && this.orientation !== 'horizontal';
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
